document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.mobileNav__list .menu-item-has-children')) {
		let subMenus = document.querySelectorAll(
			'.mobileNav__list .menu-item-has-children'
		);
		subMenus.forEach((subMenu) => {
			let link = subMenu.querySelector('a');
			let span = subMenu.querySelector('span');
			let ul = subMenu.querySelector('ul');
			link.addEventListener('click', (e) => {
				if (subMenu.querySelector('span')) {
					e.preventDefault();
					ul.classList.toggle('sub-menu--open');
					span.classList.toggle('open');
				}
			});
		});
	}
	const hamburger = document.querySelector('.header .hamburger');
	const header = document.querySelector('.header');
	const mobileNav = document.querySelector('.mobileNav');
	hamburger.addEventListener('click', () => {
		hamburger.classList.toggle('hamburger--opened');
		hamburger.setAttribute(
			'aria-expanded',
			hamburger.classList.contains('hamburger--opened')
		);
		mobileNav.classList.toggle('open');
		header.classList.toggle('header--open');
	});
	window.addEventListener('scroll', () => {
		document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
			? header.classList.add('header--scrolled')
			: header.classList.remove('header--scrolled');
	});
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	window.addEventListener('resize', () => {
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});

	if (document.querySelector('.events')) {
		const numbers = document.querySelector('.events__slider__numbers')		
		var events_slider = new Swiper('.events__slider .swiper', {
			slidesPerView: 1,
			navigation: {
				nextEl: '.events__slider__next',
				prevEl: '.events__slider__prev',
			},
			breakpoints: {
				600: {
					slidesPerView: 2,
					spaceBetween: -0
				},
				1280:{
					slidesPerView:3,
					spaceBetween: -40
				}
			},
			on:{
				init:function(sw){
					var index = sw.activeIndex
					index++;
					if(index < 10){
						index = '0'+index
					}
				var	slides = sw.slides.length
					if(slides < 10){
						slides = '0'+slides
					}
					numbers.innerHTML = '<span>'+index+'</span> / '+ slides
				},
	
				slideChange:function(sw){
					var index = sw.activeIndex
					index++;
					if(index < 10){
						index = '0'+index
					}
					var slides = sw.slides.length
					if(slides < 10){
						slides = '0'+slides
					}
					numbers.innerHTML = '<span>'+index+'</span> / '+ slides
				}

			}
		});
	}
});
