document.addEventListener('DOMContentLoaded', () => {
	const getSectionParent = (node) => {
		while (true) {
			if (node.parentNode.tagName == 'SECTION') {
				return node.parentNode;
			}
			if (node.parentNode.tagName == 'BODY') {
				return node;
			}
			node = node.parentNode;
		}
	};

	gsap.registerPlugin(ScrollTrigger);

	const fadeups = document.querySelectorAll('.fade-up');

	fadeups.forEach((fadeup) => {
		gsap
			.timeline({
				scrollTrigger: {
					trigger: getSectionParent(fadeup),
					start: 'top 75%',
				},
			})
			.fromTo(
				fadeup,
				{ autoAlpha: 0, y: 50 },
				{ autoAlpha: 1, y: 0, delay: 0.1, duration: 0.8 }
			);
	});



});
